#ele-title {
  font-size: 20px;
  margin: 5px 10px;
  margin-top: 10px;
  font-weight: 600;
  margin-left: 10px;
}

/* p {
  font-size: 10px;
} */

.element-container {
  display: block;
  background-color: white;
  padding: 0px 0px 5px;
  border-radius: 3px;
  /* box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.element-container:hover {
  cursor: pointer;
}

#brief {
  font-size: 14px;
  margin: 0px 10px;
}

#divider {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}

#bottom-info {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

#type {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  height: 16px;
  width: 100px;
  display: block;
}

#date {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline;
  height: 16px;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
}

#preview {
  width: 100%;
  height: auto;
  border-radius: 3px 3px 0px 0px;
}
