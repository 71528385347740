.home {
  margin-top: 40px;
  display: block;
  /* background-color: #e3e3e3; */
  margin-left: 8vw;
  margin-right: 8vw;
  margin-bottom: 40px;
  height: auto;
  padding: 10px;
}

.home-title {
  /* margin-left: 8vw;
  margin-right: 8vw; */
  font-size: 50px;
  text-align: center;
}

.content {
  text-align: center;
  /* margin-left: 8vw;
  margin-right: 8vw; */
  display: block;
  font-size: 1rem;
}

.home hr {
  /* margin-left: 8vw;
  margin-right: 8vw; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 40px;
}

/* About */
@media only screen and (max-width: 600px) {
  .about-chunk {
    display: block;
    margin-top: 20px;
  }

  #profile-picture {
    overflow: hidden;
    height: 50vw;
    width: 50vw;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #about-content {
    display: block;
    width: 84vw;
    text-align: left;
    margin-top: 20px;
    /* margin-top: 4vw; */
  }
}

@media only screen and (min-width: 600px) {
  #profile-picture {
    overflow: hidden;
    height: 20vw;
    width: 20vw;
    display: block;
    border-radius: 50%;
  }

  .about-chunk {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  #about-content {
    display: inline-block;
    width: 50vw;
    /* margin-top: 4vw; */
  }
}

/* What's New */
.new {
  margin-top: 40px;
}

#patch-container {
  display: flex;
  justify-content: center;
  max-width: 80vw;
}

.subhead {
  font-size: 18px;
  font-style: italic;
}

.patch-notes {
  text-align: left;
  display: block;
  width: 400px;
  max-width: 80vw;
}

/* Footer */
#footer {
  display: flex;
  justify-content: space-evenly;
}

#footer h3 {
  font-size: 1.2rem;
}

#link-list {
  orientation: vertical;
  display: block;
  margin-right: 10px;
}

#link-list a {
  display: block;
  font-size: 14px;
}

#links a {
  text-align: left;
}

.contact p {
  text-align: left;
  font-size: 0.8rem;
}

.contact {
  margin-left: 10px;
}
