.dropdown-item-checked::before {
  position: absolute;
  left: 0.4rem;
  content: "✓";
  font-weight: 600;
}

/* Portfolio Grid */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 20px;
  column-gap: 40px;
  row-gap: 40px;
  margin-left: 8vw;
  margin-right: 8vw;
  margin-bottom: 20px;
}
