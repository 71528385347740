* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none;
}

.home,
.portfolio {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
